<template>
  <b-table
    :items="items"
    :fields="fields"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    show-empty
    striped
    hover
  >
    <template #cell(acoes)="item">
      <b-dropdown variant="outline-default">
        <b-dropdown-item @click="editar(item.item)">
          {{ $t('GERAL.EDITAR') }}
        </b-dropdown-item>
        <b-dropdown-item @click="confirmarExclusao(item.item)">
          {{ $t('GERAL.EXCLUIR') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import formatador from '@/common/utils/formatador';
import mensagem from '@/common/utils/mensagem';

// Services:
import EmpresaService from '@/common/services/empresa/empresa.service';

export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'cnpj',
          label: this.$t('FINANCEIRO.CNPJ'),
          sortable: true,
          formatter: (value) => {
            return value ? formatador.cnpjComPontuacao(value) : '-';
          },
        },
        {
          key: 'razaoSocial',
          label: this.$t('FINANCEIRO.RAZAO_SOCIAL'),
        },
        {
          key: 'acoes',
          label: this.$t('FINANCEIRO.ACOES'),
        },
      ],
    };
  },
  methods: {
    editar(item) {
      this.$emit('editar', 'fornecedor', item);
    },
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('GERAL.CONFIRMAR_EXCLUSAO'),
        this.$t('GERAL.CONFIRMAR_EXCLUSAO_ITEM').formatUnicorn({
          item: this.$t('FINANCEIRO.FORNECEDOR'),
        })
      );
      respostaExclusao.isConfirmed ? this.excluir(item.id) : {};
    },
    excluir(itemId) {
      /*
				TODO: bug encontrado
				- não está sendo possível remover fornecedor :(
			*/
      this.$store.dispatch(START_LOADING);
      EmpresaService.excluirFornecedor(itemId)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('GERAL.ITEM_DELETADO').formatUnicorn({
              item: this.$t('FINANCEIRO.FORNECEDOR'),
            })
          );
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
