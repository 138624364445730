<template>
  <div :class="margin_bottom">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <!-- TÍTULO -->
      <div>
        <h3 :class="`cabecalho_${title_type}`">
          {{ $t(title) }}

          <!-- tag de status da tela -->
          <b-badge
            v-if="env == 'development' && devBadge"
            :variant="this.badge[this.devBadge]['type']"
            v-b-tooltip.hover
            :title="devBadgeTooltip"
          >
            {{ this.badge[this.devBadge]['text'] }}
          </b-badge>
        </h3>
      </div>

      <!-- BOTÕES DE AÇÃO -->
      <div class="d-flex justify-content-center align-items-center my-auto mb-3">
        <slot />

        <b-button
          v-if="btnPrimary"
          :variant="btnPrimaryVariant"
          class="mr-2"
          @click="primaryEmit"
        >
          <font-awesome-icon :icon="btnPrimaryIcon" />
          {{ $t(btnPrimaryText) }}
        </b-button>

        <b-button
          v-if="linkRetornar"
          variant="danger"
          v-b-tooltip.hover
          :title="$t('GERAL.BTN_VOLTAR_EXPLICACAO')"
          @click="irPara(linkRetornar)"
        >
          <font-awesome-icon icon="fa-solid fa-circle-left" />
        </b-button>
      </div>
    </div>

    <!-- DESCRIÇÃO -->
    <div v-if="description">
      <p class="">{{ $t(description) }}</p>
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import rotas from '@/common/utils/rotas';

export default {
  props: {
    // props base:
    title: { type: String, default: String },
    title_type: { type: String, default: String },
    description: { type: String, default: String },
    linkRetornar: { type: String, default: String },

    // props do botão adicional:
    btnPrimary: { type: Boolean, default: false },
    btnPrimaryVariant: { type: String, default: 'primary' },
    btnPrimaryText: { type: String, default: String },
    btnPrimaryIcon: { type: String, default: 'fa-solid fa-file-pen' },

    // props de estilo:
    margin_bottom: { type: String, default: 'mb-3' },

    // props para devs:
    devBadge: { type: String, default: String },
    devBadgeTooltip: { type: String, default: String },
  },
  mounted() {
    this.env = process.env.NODE_ENV;
  },
  data() {
    return {
      env: '',
      badge: {
        wip: {
          type: 'warning',
          text: '🚧 Em construção',
        },
        bug: {
          type: 'danger',
          text: '🐛 Bugs ativos',
        },
        warning: {
          type: 'warning',
          text: '⚠️ atenção',
        },
        waiting: {
          type: 'danger',
          text: '⛔ impedimento',
        },
        test: {
          type: 'info',
          text: '📝 em fase de teste',
        },
      },
    };
  },
  methods: {
    primaryEmit() {
      this.$emit('primary-method');
    },
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
<style>
.cabecalho_modal {
  font-size: medium;
}
.cabecalho_body {
  font-size: 22px;
}
</style>
