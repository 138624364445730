<template>
  <modal
    id="modalFornecedor"
    titulo="FINANCEIRO.FORNECEDOR"
    tamanho="xl"
    :exibir="exibir"
    :campos="$refs"
    @fechar="fecharModal"
  >
    <form-dados :form="form" @refs="getRefs" />

    <form-contato
      :contatos="form.contatos"
      @atualiza-form="atualizaForm"
      @excluir="excluir"
      @refs="getRefs"
    />

    <form-endereco
      :enderecos="form.enderecos"
      @atualiza-form="atualizaForm"
      @excluir="excluir"
      @refs="getRefs"
    />

    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import formatador from '@/common/utils/formatador';

// Services:
import EmpresaService from '@/common/services/empresa/empresa.service';

// Components:
import Modal from '@/components/modal/Modal';
import FormDados from './Dados';
import FormContato from './contatos/Index';
import FormEndereco from './enderecos/Index';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  components: {
    Modal,
    FormEndereco,
    FormDados,
    FormContato,
  },
  data() {
    return {
      refs: [],
    };
  },
  methods: {
    // FUNÇÕES VALIDAÇÃO FORMULÁRIO:
    getRefs(refs) {
      this.refs = refs;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    // FUNÇÕES CRUD:
    salvar() {
      if (!this.validarFormulario()) return;
      this.form.tipoEmpresa = 'Fornecedor';
      this.form.id ? this.editar() : this.criar();
    },
    criar() {
      // Formatando CNPJ para enviar ao back-end sem pontuação:
      // this.form.cnpj = formatador.cnpjSemPontuacao(this.form.cnpj);

      // Caso não haja contatos nem endereços vinculados:
      if (!this.form.contatos) this.form.contatos = [];
      if (!this.form.enderecos) this.form.enderecos = [];

      this.$store.dispatch(START_LOADING);
      EmpresaService.criarFornecedor(this.form)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Fornecedor criado!');
          this.atualizaTabela();
          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editar() {
      // Formatando CNPJ para enviar ao back-end sem pontuação:
      this.form.cnpj = formatador.cnpjSemPontuacao(this.form.cnpj);

      this.$store.dispatch(START_LOADING);
      EmpresaService.editarFornecedor(this.form)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Fornecedor editado!');
          this.atualizaTabela();
          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    excluir(tipo, item) {
      this.form[tipo].splice([item].index, 1);
      if (this.form.id) {
        switch (tipo) {
          case 'contatos':
            this.excluirContatoFornecedor(item.item.id);
            break;
          case 'enderecos':
            this.excluirEnderecoFornecedor(item.item.id);
            break;
          default:
            break;
        }
      }
    },
    excluirContatoFornecedor(id) {
      this.$store.dispatch(START_LOADING);
      EmpresaService.excluirContatoFornecedor(id)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Contato excluído!');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    excluirEnderecoFornecedor(id) {
      this.$store.dispatch(START_LOADING);
      EmpresaService.excluirEnderecoFornecedor(id)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Endereço excluído!');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÃO ATUALIZAÇÃO CONTATO/ENDEREÇO:
    atualizaForm(tipo, objeto) {
      this.form[tipo] = objeto;
    },
    // FUNÇÕES EMISSÃO DE DADOS AO PAI:
    fecharModal() {
      this.$emit('fechar', 'fornecedor');
    },
    atualizaTabela() {
      this.$emit('atualiza-tabela');
    },
  },
};
</script>
