<template lang="">
  <div class="d-flex flex-column flex-md-row topContent">
    <div class="mb-2">
      <div class="tituloPagina" v-if="titulo">
        {{ $t(titulo) }}
      </div>
      <div v-if="subtitulo">
        <p v-html="subtitulo" ></p>
      </div>
      <b-breadcrumb class="p-0 mb-0 mb-md-2" v-if="rota">
        <b-breadcrumb-item :href="homeRota" custom> Home </b-breadcrumb-item>
        <b-breadcrumb-item
          v-for="breadcrumb in breadcrumbs"
          :key="breadcrumb.titulo"
          active
          >{{ $t(breadcrumb.titulo) }}</b-breadcrumb-item
        >
      </b-breadcrumb>
    </div>
    <div class="my-2">
      <slot name="header"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titulo: { type: String, default: null },
    subtitulo: { type: String, default: null },
    breadcrumbs: { type: Array, default: () => [] },
  },
  computed: {
    rota() {
      return this.$route.path !== '/dashboard';
    },
    homeRota() {
      return process.env.BASE_URL + 'dashboard';
    },
  },
};
</script>

<style>
.tituloPagina {
  font-size: 23px !important;
  color: #1d2939 !important;
  font-weight: 700;
}

.topContent {
  justify-content: space-between;
}
</style>
