<template>
  <div class="d-flex justify-content-between mb-4">
    <div>
      <p class="titulo-medio">{{ $t(title) }}</p>
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: ['title'],
};
</script>
