<template>
  <b-row>
    <b-col md="4">
      <input-text
        ref="nomeCNPJ"
        :label="$t(`FINANCEIRO.NOME_CNPJ`)"
        :placeholder="$t(`FINANCEIRO.NOME_CNPJ_PLACEHOLDER`)"
        v-model="filtro.nomeCNPJ"
        @enter="filtrar"
      />
    </b-col>
    <b-col md="auto">
      <b-button
        class="botao-acao-filtro w-100 my-3"
        variant="primary"
        @click="filtrar"
      >
        {{ $t('GERAL.LISTAR') }}
      </b-button>
    </b-col>
    <b-col md="auto">
      <b-button
        class="botao-acao-filtro w-100 my-3"
        variant="secondary"
        @click="limparFiltro"
      >
        {{ $t('GERAL.LIMPAR') }}
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';

import { InputText } from '@/components/inputs';

export default {
  components: {
    InputText,
  },
  data() {
    return {
      filtro: {
        nomeCNPJ: '',
      },
    };
  },
  watch: {
    filtro: {
      handler(filtro) {
        this.$emit('atualizar-filtro', JSON.parse(JSON.stringify(filtro)));
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // FUNÇÕES FILTRO:
    validarFiltro() {
      return helpers.validarFormulario(this.$refs);
    },

    filtrar() {
      if (!this.validarFiltro()) return;
      this.$emit('filtrar', JSON.parse(JSON.stringify(this.filtro)));
    },

    limparFiltro() {
      this.filtro = {
        nomeCNPJ: '',
      };
    },
  },
};
</script>
