<template>
  <div :class="`mb-4 ${classeAdicional}`">
    <p class="titulo-medio">{{ $t(title) }}</p>
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: '' },
    classeAdicional: { type: String, default: '' },
  },
};
</script>
