<template>
  <div>
    <cabecalho
      title="FINANCEIRO.CONTATO"
      title_type="modal"
      :btnPrimary="true"
      btnPrimaryText="GERAL.ADICIONAR"
      @primary-method="abrirFecharForm"
    />

    <!-- Formulário -->
    <div v-if="formulario.exibir" class="row">
      <div class="col-12">
        <input-text
          ref="nome"
          v-model="formulario.dados.nome"
          :label="$t('FINANCEIRO.NOME_CONTATO')"
          :placeholder="$t('FINANCEIRO.NOME_CONTATO')"
          :maxLength="60"
        />
      </div>
      <div class="col-12">
        <input-text
          ref="descricao"
          v-model="formulario.dados.descricao"
          :label="$t('FINANCEIRO.CONTATO')"
          :placeholder="$t('FINANCEIRO.CONTATO_PLACEHOLDER')"
          :maxLength="60"
        />
      </div>

      <div class="col-12 mb-4">
        <b-button variant="primary" @click="adicionarContato">
          {{ $t('FINANCEIRO.ADICIONAR_CONTATO') }}
        </b-button>
      </div>
    </div>

    <!-- Tabela -->
    <tabela-contatos :items="contatosCopia" @excluir="excluir" />
  </div>
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';

// Components:
import { Cabecalho } from '@/components/headers';
import { InputText } from '@/components/inputs';
import TabelaContatos from './Tabela';

export default {
  props: {
    contatos: { type: Array, default: Array },
  },
  components: {
    Cabecalho,
    InputText,
    TabelaContatos,
  },
  data() {
    return {
      contatosCopia: [],
      formulario: {
        exibir: false,
        dados: {},
      },
    };
  },
  mounted() {
    this.contatosCopia = this.contatos;
  },
  methods: {
    // FUNÇÕES CRUD:
    adicionarContato() {
      if (!this.validarFormulario()) return;
      this.contatosCopia.push(this.formulario.dados);
      this.formulario.dados = {};
      this.formulario.exibir = false;
      this.$emit('atualiza-form', 'contatos', this.contatosCopia);
    },
    excluir(tipo, item) {
      if (!item.item.id) {
        return this.contatosCopia.splice(item.index, 1);
      }
      this.$emit('excluir', tipo, item);
    },
    // FUNÇÕES DE VALIDAÇÃO FORM:
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    // FUNÇÕES AUXILIARES:
    abrirFecharForm() {
      this.formulario.exibir == false
        ? (this.formulario.exibir = true)
        : (this.formulario.exibir = false);
    },
  },
};
</script>
