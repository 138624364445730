<template>
  <b-table
    :fields="fields"
    :items="items"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    responsive
    show-empty
    striped
    hover
  >
    <template #cell(acoes)="item">
      <b-dropdown variant="outline-default">
        <b-dropdown-item @click="confirmarExclusao(item)">
          {{ $t('FINANCEIRO.EXCLUIR_ENDERECO') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
// Utils & Aux:
import mensagem from '@/common/utils/mensagem';

export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'cep',
          label: this.$t('FINANCEIRO.CEP'),
          sortable: true,
        },
        {
          key: 'bairro',
          label: this.$t('FINANCEIRO.ENDERECO_BAIRRO'),
          sortable: true,
        },
        {
          key: 'logradouro',
          label: this.$t('FINANCEIRO.ENDERECO_LOGRADOURO'),
          sortable: true,
        },
        {
          key: 'numero',
          label: this.$t('FINANCEIRO.ENDERECO_NUMERO'),
          sortable: true,
        },
        {
          key: 'complemento',
          label: this.$t('FINANCEIRO.ENDERECO_COMPLEMENTO'),
          sortable: true,
        },
        {
          key: 'acoes',
          label: 'Ações',
        },
      ],
    };
  },
  methods: {
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        null,
        this.$t('FINANCEIRO.CONFIRMAR_EXCLUIR_ENDERECO')
      );
      if (respostaExclusao.isConfirmed) {
        this.$emit('excluir', 'enderecos', item);
      }
    },
  },
};
</script>
