<template>
  <b-row v-if="total > 0" class="mt-4">
    <b-col
      cols="12"
      md="4"
      class="col-xs-6 d-flex justify-content-center justify-content-md-start align-items-end"
    >
      <p class="d-block text-center text-md-left">
        {{
          $t('GERAL.TOTAL_REGISTROS').formatUnicorn({
            count: total,
          })
        }}
      </p>
    </b-col>
    <b-col
      cols="12"
      md="4"
      class="d-flex justify-content-center align-items-center mb-2"
    >
      <b-pagination
        v-model="content.numeroDaPagina"
        :total-rows="total"
        :per-page="content.tamanhoDaPagina"
        aria-controls="my-table"
        class=""
      />
    </b-col>
    <b-col
      cols="12"
      md="4"
      class="conteudoTamanho justify-content-md-end justify-content-center mb-2"
    >
      <span>{{ $t('GERAL.EXIBIR') }}</span>
      <div class="paginacaoTamanho">
        <input-select
          ref="tamanhoDaPagina"
          v-model="content.tamanhoDaPagina"
          noLabel
          :options="optionsPagina"
          @change="change"
          hideFirstOption
        />
      </div>
      <span>{{ $t('GERAL.REGISTROS_POR_PAGINA') }}</span>
    </b-col>
  </b-row>
</template>

<script>
// Components:
import { InputSelect } from '@/components/inputs';

export default {
  name: 'Paginacao',
  components: { InputSelect },
  props: {
    value: { type: [String, Number, Object, Array] },
    total: { type: Number, default: 0 },
  },
  data() {
    return {
      content: this.value,
      optionsPagina: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 30, text: 30 },
        { value: 50, text: 50 },
        { value: 100, text: 100 },
      ],
    };
  },
  watch: {
    'content.tamanhoDaPagina': 'emitContent',
    'content.numeroDaPagina': 'emitContent',
    value: 'assignValue',
  },
  methods: {
    assignValue(value) {
      this.content = value;
    },
    change() {
      this.content.numeroDaPagina = 1;
    },
    emitContent() {
      this.$emit('input', this.content);
    },
  },
};
</script>

<style>
.conteudoTamanho {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  align-items: center;
}

.conteudoTamanho .form-group {
  margin-bottom: 0 !important;
}
.paginacaoTamanho {
  max-width: 70px;
  text-align: center;
  flex: 1;
}

.b-pagination {
  gap: 5px;
  margin-bottom: 0 !important;
}

.b-pagination * {
  color: #000000;
}

.b-pagination button,
.b-pagination .page-link {
  padding: auto 9px !important;
  border-radius: 4px !important;
  border: 1px solid var(--gray-4, #ced4da);
  background: var(--White, #fff);
}
.b-pagination .page-item.active button {
  background-color: #e0f6ff;
  border-color: #a4dbff !important;
  color: #13327a !important;
  font-weight: 700;
}

li.page-item[role='separator'] span {
  /* Estilos desejados */
  background: transparent !important;
  border: 0;
}
</style>
