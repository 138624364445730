<template>
  <div>
    <cabecalho
      title="FINANCEIRO.ENDERECO"
      title_type="modal"
      :btnPrimary="true"
      btnPrimaryText="GERAL.ADICIONAR"
      @primary-method="abrirFecharForm"
    />

    <!-- Formulário -->
    <formulario-endereco
      :exibir="formulario.exibir"
      :enderecos="enderecosCopia"
      @fechar-form="fecharForm"
      @atualiza-form="atualizaForm"
    />

    <!-- Tabela -->
    <tabela-enderecos :items="enderecosCopia" @excluir="excluir" />
  </div>
</template>
<script>
// Components:
import { Cabecalho } from '@/components/headers';
import FormularioEndereco from './Form';
import TabelaEnderecos from './Tabela';

export default {
  props: {
    enderecos: { type: Array, default: Array },
  },
  components: {
    Cabecalho,
    FormularioEndereco,
    TabelaEnderecos,
  },
  mounted() {
    this.enderecosCopia = this.enderecos;
  },
  data() {
    return {
      enderecosCopia: [],
      formulario: {
        exibir: false,
        dados: {},
      },
    };
  },
  methods: {
    // FUNÇÕES CRUD:
    excluir(tipo, item) {
      this.$emit('excluir', tipo, item);
    },
    atualizaForm(enderecos) {
      this.$emit('atualiza-form', 'enderecos', enderecos);
    },
    // FUNÇÕES AUXILIARES:
    abrirFecharForm() {
      this.formulario.exibir == false
        ? (this.formulario.exibir = true)
        : (this.formulario.exibir = false);
    },
    fecharForm() {
      this.formulario.exibir = false;
    },
  },
};
</script>
