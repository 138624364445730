import router from "@/router/Router";

const irPara = (rota, query = "", linkRetornar = "", dados) => {
    query || linkRetornar || dados
        ? router.push({ name: rota, params: { id: query, linkRetornar, dados } })
        : router.push({ name: rota });
};

const retornar = () => {
    router.go(-1);
};

export default {
    irPara,
    retornar
};
