<template>
  <div class="row">
    <div class="col-12">
      <cabecalho title="FINANCEIRO.DADOS" title_type="modal" />
    </div>
    <div class="col-12 col-md-6">
      <input-mask
        ref="cnpj"
        v-model="form.cnpj"
        label="CNPJ"
        type="text"
        :mask="['##.###.###/####-##']"
        placeholder="Digite apenas números"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-text
        ref="inscricaoMunicipal"
        v-model="form.inscricaoMunicipal"
        type="text"
        :label="$t('FINANCEIRO.INSCRICAO_MUNICIPAL')"
        :placeholder="$t('FINANCEIRO.INSCRICAO_MUNICIPAL')"
        :maxLength="11"
      />
    </div>
    <div class="col-12 col-md-6">
      <input-text
        ref="razaoSocial"
        v-model="form.razaoSocial"
        type="text"
        :label="$t('FINANCEIRO.RAZAO_SOCIAL')"
        :placeholder="$t('FINANCEIRO.RAZAO_SOCIAL')"
        :maxLength="255"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-text
        ref="nomeFantasia"
        v-model="form.nomeFantasia"
        type="text"
        :label="$t('FINANCEIRO.NOME_FANTASIA')"
        :placeholder="$t('FINANCEIRO.NOME_FANTASIA')"
        :maxLength="255"
        required
      />
    </div>
  </div>
</template>
<script>
// Components:
import { Cabecalho } from '@/components/headers';
import { InputMask, InputText } from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    Cabecalho,
    InputMask,
    InputText,
  },
  mounted() {
    this.$emit('refs', this.$refs);
  },
};
</script>
