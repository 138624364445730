import ApiService from '@/common/api/api.service'

const EnderecoService = {
  async buscarNacionalidades () {
    let { data } = await ApiService.get(`SubDivisaoPais/obter-paises`)
    return data
  },
  async buscarEstados (paisId) {
    let { data } = await ApiService.get(
      `SubDivisaoPais/obter-estados/${paisId}`
    )
    return data
  },
  async buscarMunicipios (estadoId) {
    let { data } = await ApiService.get(
      `SubDivisaoPais/obter-municipios/${estadoId}`
    )
    return data
  },

  async buscarMunicipio (naturalidadeId) {
    let { data } = await ApiService.get(
      `SubDivisaoPais/obter-municipio/${naturalidadeId}`
    )
    return data
  }
}

export default EnderecoService
