const abrirModal = (local, key, item) => {
    local.modais[key] = true
    item ? local.modais[`${key}Dados`] = JSON.parse(JSON.stringify(item)) : local.modais[`${key}Dados`] = {}
}

const fecharModal = (local, key) => { local.modais[key] = false }

export default {
    abrirModal,
    fecharModal
}