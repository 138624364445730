import ApiService from '@/common/api/api.service'

let apiBasePath = 'Empresa'

const EmpresaService = {
    // FUNÇÕES FORNECEDOR:
    async criarFornecedor(form) {
        let { data } = await ApiService.post(`${apiBasePath}/fornecedor/adicionar`, form)
        return data
    },
    async editarFornecedor(form) {
        let { data } = await ApiService.put(`${apiBasePath}/fornecedor/atualizar`, form)
        return data
    },
    async excluirFornecedor(itemId) {
        let { data } = await ApiService.delete(`${apiBasePath}/fornecedor/remover/${itemId}`)
        return data
    },
    async excluirContatoFornecedor(itemId) {
        let { data } = await ApiService.delete(`${apiBasePath}/fornecedor/remover-contato/${itemId}`)
        return data
    },
    async obterFornecedorId(itemId) {
        let { data } = await ApiService.get(`${apiBasePath}/fornecedor/obter/${itemId}`)
        return data
    },
    async obterTodosFornecedoresSemPaginacao() {
        let { data } = await ApiService.get(`${apiBasePath}/fornecedor/obter-todos-sem-paginacao`)
        return data
    },
    async obterFornecedoresPorNome(nome) {
        let { data } = await ApiService.get(`${apiBasePath}/fornecedor/obter-com-paginacao`, {
            params: {
                Nome: nome,
                TamanhoDaPagina: 50
            },
        });
        data.data.itens = data.data.itens.map((row) => ({
            ...row,
            text: `${row.cnpj} - ${row.razaoSocial}`,
            value: row.id,
        }));
        return data.data;
    },
    async obterTodosFornecedores(paginacao, filtro) {
        let { data } = await ApiService.get(`${apiBasePath}/fornecedor/obter-todos`,
            {
                params: {
                    TamanhoDaPagina: paginacao.tamanhoDaPagina,
                    NumeroDaPagina: paginacao.numeroDaPagina,
                    filtro,
                }
            }
        )
        return data
    },

    // FUNÇÕES CONVENIADO:
    async criarConveniado(form) {
        let { data } = await ApiService.post(`${apiBasePath}/conveniado/adicionar`, form)
        return data
    },
    async editarConveniado(form) {
        let { data } = await ApiService.put(`${apiBasePath}/conveniado/atualizar`, form)
        return data
    },
    async excluirConveniado(itemId) {
        let { data } = await ApiService.delete(`${apiBasePath}/conveniado/remover/${itemId}`)
        return data
    },
    async excluirContatoConveniado(itemId) {
        let { data } = await ApiService.delete(`${apiBasePath}/conveniado/remover-contato/${itemId}`)
        return data
    },
    async excluirEnderecoConveniado(itemId) {
        let { data } = await ApiService.delete(`${apiBasePath}/conveniado/remover-endereco/${itemId}`)
        return data
    },
    async obterConveniado(itemId) {
        let { data } = await ApiService.get(`${apiBasePath}/conveniado/obter/${itemId}`)
        return data
    },
    async obterTodosConveniados(paginacao) {
        let { data } = await ApiService.get(`${apiBasePath}/conveniado/obter-todos`,
            {
                params: {
                    TamanhoDaPagina: paginacao.tamanhoDaPagina,
                    NumeroDaPagina: paginacao.numeroDaPagina
                }
            }
        )
        return data
    },
}

export default EmpresaService