<template>
  <div class="d-flex justify-content-between mb-4">
    <div>
      <p class="titulo-medio">{{ $t(title) }}</p>
    </div>
    <div class="d-flex">
      <slot />
      <b-button variant="danger" class="m-auto" @click="irPara(link)">
        {{ $t('GERAL.RETORNAR') }}
      </b-button>
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import rotas from '@/common/utils/rotas';

export default {
  props: {
    title: { type: String, default: String },
    link: { type: String, default: String },
  },
  methods: {
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
