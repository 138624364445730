<template>
  <div>
    <titulo-fornecedores @cadastrar="openModal" />
    <filtro-fornecedores
      @filtrar="getFornecedores"
      @atualizar-filtro="atualizarFiltro"
    />

    <tabela :items="items" @editar="openModal" />

    <paginacao
      v-model="paginacao.paginacao"
      :total="paginacao.total"
      @input="getFornecedores"
    />

    <modal-fornecedor
      :exibir="modais.fornecedor"
      :form="modais.fornecedorDados"
      @atualiza-tabela="getFornecedores"
      @fechar="closeModal"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import modais from '@/common/utils/modais';

// Services:
import EmpresaService from '@/common/services/empresa/empresa.service';

// Components:
import Tabela from './components/Tabela';
import ModalFornecedor from './components/modal/Index';
import Paginacao from '@/components/paginacao/Paginacao';
import TituloFornecedores from '@/views/financeiro/estrutura/fornecedores/components/TituloFornecedores';
import FiltroFornecedores from './components/FiltroFornecedores';

export default {
  components: {
    TituloFornecedores,
    FiltroFornecedores,
    Tabela,
    ModalFornecedor,
    Paginacao,
  },
  data() {
    return {
      items: [],
      filtro: {
        nomeCNPJ: '',
      },
      modais: {
        fornecedor: false,
        fornecedorDados: {},
      },
      paginacao: {
        paginacao: { numeroDaPagina: 1, tamanhoDaPagina: 10 },
        total: 0,
      },
    };
  },
  mounted() {
    this.getFornecedores();
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getFornecedores() {
      this.$store.dispatch(START_LOADING);
      EmpresaService.obterTodosFornecedores(
        this.paginacao.paginacao,
        this.filtro.nomeCNPJ
      )
        .then(({ data }) => {
          this.items = data.itens;
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // FUNÇÕES FILTRO
    atualizarFiltro(value) {
      this.filtro = value;
    },

    // FUNÇÕES MODAL
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
  },
};
</script>
