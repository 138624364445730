<template>
    <topbar
      :titulo="$t('FINANCEIRO.FORNECEDORES')"
      :subtitulo="$t('FINANCEIRO.FORNECEDORES_DESCRICAO_PAGE')"
      :breadcrumbs="breadcrumbs"
    >
      <template #header>
        <div class="d-flex justify-content-between ml-md-4 gap-1">
          <div></div>
          <b-button variant="info" @click="cadastrar">
            {{ $t('GERAL.CADASTRAR') }}
          </b-button>
        </div>
      </template>
    </topbar>
  </template>
  <script>
  // Utils & Aux:
  import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';
  
  export default {
    components: { Topbar },
    data() {
      return {
        breadcrumbs: [
        { titulo: 'FINANCEIRO.FINANCEIRO' },
      { titulo: 'FINANCEIRO.FORNECEDORES' },
        ],
      };
    },
    methods: {
      // FUNÇÕES AUXILIARES:
      cadastrar() {
        this.$emit('cadastrar', 'fornecedor');
      },
    },
  };
  </script>
        