<template>
  <div v-if="exibir" class="row">
    <div class="col-12 col-md-4">
      <input-select-search
        ref="paisId"
        :label="$t(`FINANCEIRO.ENDERECO_PAIS`)"
        :options="gets.listaPais"
        v-model="formulario.dados.paisId"
        :isDisabled="true"
      />
    </div>
    <div class="col-12 col-md-4">
      <input-mask
        ref="cep"
        v-model="formulario.dados.cep"
        :label="$t('FINANCEIRO.CEP')"
        :mask="['#####-###']"
        placeholder="Digite apenas números"
        type="text"
      />
    </div>
    <div class="col-12 col-md-4">
      <input-select-search
        ref="estadoId"
        :label="$t(`FINANCEIRO.ENDERECO_ESTADO`)"
        placeholder="Digite ou selecione"
        :options="gets.listaEstado"
        v-model="formulario.dados.estadoId"
      />
    </div>
    <div class="col-12 col-md-4">
      <input-select-search
        ref="municipioId"
        :label="$t(`FINANCEIRO.ENDERECO_MUNICIPIO`)"
        placeholder="GERAL.DIGITE_OU_SELECIONE"
        :options="gets.listaMunicipio"
        v-model="formulario.dados.municipioId"
      />
    </div>
    <div class="col-12 col-md-4">
      <input-text
        ref="bairro"
        v-model="formulario.dados.bairro"
        :label="$t('FINANCEIRO.ENDERECO_BAIRRO')"
        :placeholder="$t('FINANCEIRO.ENDERECO_BAIRRO')"
      />
    </div>
    <div class="col-12 col-md-4">
      <input-text
        ref="logradouro"
        v-model="formulario.dados.logradouro"
        :label="$t('FINANCEIRO.ENDERECO_LOGRADOURO')"
        :placeholder="$t('FINANCEIRO.ENDERECO_LOGRADOURO')"
      />
    </div>
    <div class="col-12 col-md-2">
      <input-text
        ref="numero"
        v-model="formulario.dados.numero"
        :label="$t('FINANCEIRO.ENDERECO_NUMERO')"
        :placeholder="$t('FINANCEIRO.ENDERECO_NUMERO')"
      />
    </div>
    <div class="col-12 col-md-10">
      <input-text
        ref="complemento"
        v-model="formulario.dados.complemento"
        :label="$t('FINANCEIRO.ENDERECO_COMPLEMENTO')"
        :placeholder="$t('FINANCEIRO.ENDERECO_COMPLEMENTO')"
      />
    </div>
    <div class="col-12 mb-4">
      <b-button variant="primary" @click="adicionarEndereco">
        {{ $t('FINANCEIRO.ADICIONAR_ENDERECO') }}
      </b-button>
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import axios from 'axios';

// Services:
import EnderecoService from '@/common/services/endereco/endereco.service';

// Components:
import { InputMask, InputText, InputSelectSearch } from '@/components/inputs';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    enderecos: { type: Array, default: Array },
  },
  components: {
    InputMask,
    InputText,
    InputSelectSearch,
  },
  mounted() {
    this.getPaises();
    this.formulario.exibir = this.exibir;
  },
  data() {
    return {
      enderecoEditar: {},
      dadosPesquisaCEP: [],
      formulario: {
        exibir: false,
        dados: {},
      },
      gets: {
        brasil: [],
        listaPais: [],
        listaEstado: [],
        listaMunicipio: [],
        listaTipoEndereco: [],
      },
    };
  },
  watch: {
    exibir: 'verificaNovoOuEditar',
    'formulario.dados.cep': 'buscaDadosCEP',
    'formulario.dados.estadoId': 'getMunicipios',
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getPaises() {
      this.$store.dispatch(START_LOADING);
      EnderecoService.buscarNacionalidades()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.nome,
          }));
          this.gets.listaPais = data;

          // OBSERVAÇÃO: atualmente fazemos busca de estado BR então salvo o BR direto no this.gets.brasil
          this.getBrasil();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getBrasil() {
      this.gets.listaPais.forEach((element) => {
        if (element.nome == 'Brasil') {
          this.formulario.dados.paisId = element.id;
          return (this.gets.brasil = element);
        }
      });
    },
    getEstados() {
      this.$store.dispatch(START_LOADING);
      EnderecoService.buscarEstados(this.gets.brasil.id)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.nome,
          }));
          this.gets.listaEstado = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getMunicipios() {
      this.$store.dispatch(START_LOADING);
      if (this.formulario.dados.estadoId)
        EnderecoService.buscarMunicipios(this.formulario.dados.estadoId)
          .then(({ data }) => {
            data = data.map((row) => ({
              ...row,
              value: row.id,
              text: row.nome,
            }));
            this.gets.listaMunicipio = data;
            if (this.dadosPesquisaCEP) {
              this.encontrarMunicipio();
            }
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
    },
    encontrarMunicipio() {
      this.gets.listaMunicipio.forEach((element) => {
        if (element.nome == this.dadosPesquisaCEP.localidade) {
          this.formulario.dados.municipioId = element.id;
        }
      });
    },
    verificaNovoOuEditar() {
      this.formulario.id
        ? (this.formulario.dados = this.enderecoEditar)
        : (this.formulario.dados = {});
      this.getPaises();
      this.getEstados();
      this.getMunicipios();
    },
    buscaDadosCEP() {
      if (this.formulario.dados.cep && this.formulario.dados.cep.length == 9) {
        axios
          .get(`https://viacep.com.br/ws/${this.formulario.dados.cep}/json/`)
          .then((response) => {
            if (response.data) {
              if (response.data.erro) {
                this.formulario.dados = {};
                this.formulario.dados.paisId = this.gets.brasil.id;
                this.formulario.dados.cep = '';
                mensagem.erro(this.$t('CAMPOS_VALIDACAO.CEP_INEXISTENTE'));
                return;
              }

              this.dadosPesquisaCEP = response.data;
              if (this.dadosPesquisaCEP) {
                this.preenchimentoAutomatico();
              }
            }
          })
          .catch((error) => {
            this.formulario.dados = {};
            this.formulario.dados.paisId = this.gets.brasil.id;
            this.formulario.dados.cep = '';
            mensagem.erro(this.$t('CAMPOS_VALIDACAO.CEP_INEXISTENTE'), error);
          });
      }
    },
    preenchimentoAutomatico() {
      this.gets.listaEstado.forEach((element) => {
        if (element.sigla == this.dadosPesquisaCEP.uf) {
          this.estadoId = element.id;
          this.formulario.dados.estadoId = element.id;
          // se encontrar o estado, está apto para munícipio:
          if (this.formulario.dados.estadoId) {
            this.getMunicipios();
          }
        }
      });
      this.formulario.dados.bairro = this.dadosPesquisaCEP.bairro;
      this.formulario.dados.logradouro = this.dadosPesquisaCEP.logradouro;
    },
    // FUNÇÕES CRUD:
    adicionarEndereco() {
      if (!this.validarFormulario()) return;
      this.formulario.dados.cep = this.formulario.dados.cep
        .replace('-', '')
        .replace('.', '');

      this.enderecos.push(this.formulario.dados);
      this.formulario.dados = {};
      this.$emit('atualiza-form', this.enderecos);
      this.$emit('fechar-form');
    },
    // FUNÇÕES DE VALIDAÇÃO FORM:
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
  },
};
</script>
